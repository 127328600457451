<!--
 * @Author       : Hugo
 * @Date         : 2021-12-06 11:15:47
 * @LastEditTime : 2022-08-17 14:09:03
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/components/PopUp/FileSorting/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <common-wrapper
    max_width="800px"
    max_height="600px"
  >
    <div class="components_pop_up_at_index d-flex flex-column">
      
      <common-title
        :title       = "title"
        class        = "flex-shrink-0"
        @handleClose = "handleClose"
        close
      ></common-title>
      <div class="main">
        <list
          :list="list"
          @handleSort="handleSort"
        ></list>
       
      </div>
      <common-operation
        @handleClose="handleClose"
        @handleSure="handleSure"
      ></common-operation>
    </div>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper';
import CommonTitle from '@/components/PopUp/Common/Title';
import CommonOperation from '@/components/PopUp/Common/Operation.vue';
import List from './List.vue';


export default {
  components: {
    CommonWrapper,
    CommonTitle,
    CommonOperation,
    List,
  },
  props: {
    title: {
      type   : String,
      default: '修改文件排序'
    },
    parent: {
      type   : Object,
      default: null,
    },
    widget: {
      type   : Object,
      default: null,
    },
    list: {
      type   : Array,
      default: null,
    },
  },
  data(){
    return {
      search  : '',
      self_id : this.$tools.getUserId(),
      modified: false,
    }
  },
  methods: {
    handleClose(){
      this.$emit('handleClose');
    },
    handleSure(){
      const {parent, widget, list, modified} = this;
      this.$emit('handleSure', {
        parent, widget, list, modified
      })
    },
    handleSort(modified){
      this.modified = modified;
    },
  
   
  
  },
  mounted(){
    // // console.log(this.$store.state.dialog_users);
    
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_at_index{
  width : 100%;
  height: 100%;
  .common_border{
    padding: {
      left : 30px;
      right: 30px;
    }
  }
  .main{
    @include scrollbar;
    width   : 100%;
    overflow: auto;
    flex    : 1;
  }
  // .sticky_group{
  //   position: relative;
  //   width: 100%;
  //   top:0;
  //   left: 0;
  //   position: sticky;
  //   background-color: #fff;
  // }
  // .list_group{
    
  // }
}
</style>