<!--
 * @Author       : Hugo
 * @Date         : 2021-12-06 11:49:22
 * @LastEditTime : 2021-12-07 10:57:47
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/Common/Operation.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_common_operation d-flex align-center justify-end py-10 bbox flex-shrink-0"
    :style="{
      'border-radius': `0 0 ${border_radius} ${border_radius}`
    }"
  >
    <div class="btn btn_light cancel"
      @click.stop="handleClose();"
    >{{cancel_text}}</div>
    <div
      class="btn btn_dark sure ml-16"
      @click.stop="handleSure();"
    >{{sure_text}}</div>
    
  </div>
</template>


<script>
export default {
  props: {
    border_radius: {
      type: String,
      default: '4px',
    },
    sure_text: {
      type: String,
      default: '确定',
    },
    cancel_text: {
      type: String,
      default: '取消',
    },
  },
  methods: {
    handleClose(){
      this.$emit('handleClose');
    },
    handleSure(){
      this.$emit('handleSure');
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_common_operation{
  width: 100%;
  font-size: 14px;
  border-top: 1px solid #f8f8f8;
  padding: {
    left: 30px;
    right: 30px;
  }
  .btn{
    line-height: 40px;
    width: 80px;
    text-align: center;
    border-radius: 4px;
  }
}
</style>
