var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "components_pop_up_file_sorting_list",
      attrs: {
        list: _vm.list,
        group: { name: `file_sorting`, pull: false },
        handle: ".components_pop_up_file_sorting_list .unit .sort",
        animation: "300",
      },
      on: { change: _vm.handleChange },
    },
    [
      _vm._l(_vm.list, function (item) {
        return [
          item.type !== 1
            ? _c("file", {
                key: item.id || item.url,
                staticClass: "unit",
                attrs: { file: item, sort: true },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }