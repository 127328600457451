<!--
 * @Author       : Hugo
 * @Date         : 2021-12-06 14:42:20
 * @LastEditTime : 2021-12-09 10:22:20
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/FileSorting/List.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <draggable
    class="components_pop_up_file_sorting_list"
    :list="list"
    :group="{name:`file_sorting`, pull: false}"
    handle=".components_pop_up_file_sorting_list .unit .sort"
    animation="300"
    @change="handleChange"
  >
    <template
      v-for="item in list"
    >
      <file
        class = "unit"
        :key  = "item.id || item.url"
        :file = "item"
        :sort = "true"
        v-if  = "item.type !== 1"
      ></file>
    </template>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import File from '@/components/Task/Main/Widget/Content/Part/File.vue';
import {PFile} from '@/types';
export default {
  components: {
    File,
    draggable,
  },
  props: {
    list: {
      type: Array,
      default: null,
    }
  },
  methods: {
    PFile(item){
      return new PFile(item);
    },
    handleChange(e){
      // console.log(e);
      const {moved} = e;
      if(!moved){
        return false;
      }
      const {newIndex, oldIndex} = moved;
      this.$set(this.list[newIndex], 'sorting', true); // 排序改动标记
      this.$set(this.list[oldIndex], 'sorting', true); // 排序改动标记
      this.$emit('handleSort', true);
    }
  },
  mounted() {
    // console.log(this.list);
  }
}
</script>