var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_common_operation d-flex align-center justify-end py-10 bbox flex-shrink-0",
      style: {
        "border-radius": `0 0 ${_vm.border_radius} ${_vm.border_radius}`,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "btn btn_light cancel",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleClose()
            },
          },
        },
        [_vm._v(_vm._s(_vm.cancel_text))]
      ),
      _c(
        "div",
        {
          staticClass: "btn btn_dark sure ml-16",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleSure()
            },
          },
        },
        [_vm._v(_vm._s(_vm.sure_text))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }