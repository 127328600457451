var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-wrapper",
    { attrs: { max_width: "800px", max_height: "600px" } },
    [
      _c(
        "div",
        { staticClass: "components_pop_up_at_index d-flex flex-column" },
        [
          _c("common-title", {
            staticClass: "flex-shrink-0",
            attrs: { title: _vm.title, close: "" },
            on: { handleClose: _vm.handleClose },
          }),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("list", {
                attrs: { list: _vm.list },
                on: { handleSort: _vm.handleSort },
              }),
            ],
            1
          ),
          _c("common-operation", {
            on: { handleClose: _vm.handleClose, handleSure: _vm.handleSure },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }